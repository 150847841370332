import clsx from 'clsx';
import {isNil} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import React, {ChangeEvent, useEffect, useState, useMemo, BaseSyntheticEvent, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as BinIcon} from '../../../../assets/images/icon-bin.svg';
import {ReactComponent as DownloadIcon} from '../../../../assets/images/icon-download.svg';
import {ReactComponent as PdfLogo} from '../../../../assets/images/pdf-logo.svg';
import {ReactComponent as PlayLogo} from '../../../../assets/images/play-logo.svg';
import {blobToBase64String} from '../../../../utils/file-upload-utils';
import {downloadBlobFile} from '../../../../utils/file-download-utils';
import IconButtonSwitch from '../../icon-button-switches/IconButtonSwitch';
import {
  AcceptedFileProperties,
  FileTypes,
  FileUploadError,
  FileUploadErrorCode,
  MaximumFileSize
} from './file-upload.model';
import FilePreviewDialog from './FilePreviewDialog';
import styles from './NxFileUpload.module.scss';
import {ACCEPTED_AUDIO_FILE_TYPES, ACCEPTED_IMAGE_FILE_TYPES} from '../../../../constants/file-uploads';
import {AppSnackbarContext} from '../../app-snackbar-provider/AppSnackbarProvider';
import {getFileExtension, getFileExtensionName} from '../../../../utils/file-utils';

const TransPrefix = 'COMMON.FILE_UPLOAD';

export interface FileUploadProps {
  acceptedFileProperties?: AcceptedFileProperties[];
  className?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  onBlur?: () => void;
  onChange: (file?: File) => Promise<void> | void;
  onChangeFailed?: (error: FileUploadError) => void;
  required?: boolean;
  showAcceptedFileTypesInfo?: boolean;
  value?: File;
  externalError?: string;
}

const NxFileUpload = (
  {
    acceptedFileProperties,
    className,
    disabled,
    label,
    onBlur,
    onChange,
    onChangeFailed,
    required = false,
    showAcceptedFileTypesInfo = true,
    value,
    externalError
  }: FileUploadProps): React.ReactElement => {

  const {t} = useTranslation();
  const [image, setImage] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [error, setError] = useState<string>();
  const {showSuccessMessage} = useContext(AppSnackbarContext);

  useEffect(() => {
    if (value) {
      // If value exists initial set value
      (async (): Promise<void> => {
        await setFileParameters(value);
      })();
    } else {
      // If value absent reset from
      setImage(undefined);
      setFileName(undefined);
    }
  }, [value]);

  const getFlattenFileTypes = (): FileTypes[] => flatten(acceptedFileProperties?.map(property => property.types));
  const getSizeInMb = (size?: number): string => size && (size >= 1000000
    ? `${size / 1024 / 1024} MB`
    : `${size / 1024} KB`) || '';

  const isFilePdf = useMemo(
    () => value && getFileExtension(value) === getFileExtensionName(FileTypes.APPLICATION_PDF),
    [value]
  );

  const isFileImage = useMemo(
    () =>
      value && ACCEPTED_IMAGE_FILE_TYPES.some((fileType) => getFileExtensionName(fileType) === getFileExtension(value)),
    [value]
  );

  const isFileAudio = useMemo(
    () =>
      value && ACCEPTED_AUDIO_FILE_TYPES.some((fileType) => getFileExtensionName(fileType) === getFileExtension(value)),
    [value]
  );

  const isFileVideo = useMemo(
    () => value && getFileExtension(value) === getFileExtensionName(FileTypes.VIDEO_MP4),
    [value]
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0] ?? null;

    if (isNil(file)) {
      return;
    }

    const fileExtension = getFileExtension(file);
    if (!isEmpty((acceptedFileProperties))) {
      const flattenTypes = getFlattenFileTypes();
      const isNotAllowed = isNil(flattenTypes?.map(getFileExtensionName).find(ext => ext === fileExtension));
      if (isNotAllowed) {
        handleFileAttachError({
          errorCode: FileUploadErrorCode.FILE_TYPE_NOT_SUPPORTED,
          message: t(`${TransPrefix}.ERROR.FILE_TYPE_NOT_SUPPORTED`)
        });
        return;
      }
    }

    const fileProperty = acceptedFileProperties?.find(property => property.types.some(type => getFileExtensionName(type) === fileExtension));
    const maximumFileSize = fileProperty?.maxSize || MaximumFileSize.DEFAULT;

    if (file.size > maximumFileSize) {

      // Change bytes to MB / KB
      const maximumSize = maximumFileSize >= 1000000
        ? `${maximumFileSize / 1024 / 1024} MB`
        : `${maximumFileSize / 1024} KB`;

      handleFileAttachError({
        errorCode: FileUploadErrorCode.FILE_TOO_LARGE,
        message: t(`${TransPrefix}.ERROR.FILE_TOO_LARGE`, {maximumSize})
      });

      return;
    }

    handleFileAttached(file);
  };

  const handleResetFileValue = (event: BaseSyntheticEvent): void => {
    event.target.value = null;
  };

  const handleFileAttached = async (file: File): Promise<void> => {
    setError(undefined);
    await setFileParameters(file);
    onChange(file);
    showSuccessMessage(t(`${TransPrefix}.SUCCESS`));
  };

  const handleFileAttachError = (error: FileUploadError): void => {
    if (onChangeFailed) {
      onChangeFailed(error);
    }

    setError(error.message);
    console.error(error.message);
  };

  const setFileParameters = async (file: File): Promise<void> => {
    const imageString = await blobToBase64String(file);

    setImage(imageString);
    setFileName(file.name);
  };

  const removeFile = (): void => {
    setImage(undefined);
    setFileName(undefined);
    onChange(undefined);
  };

  const downloadFile = (): void => {
    downloadBlobFile(value);
  };

  const LabelWithAcceptedFilesInfo = (
    <div className={styles.label}>
      {label} {required && '*'}
      {
        showAcceptedFileTypesInfo && acceptedFileProperties &&
        <div className={styles.fileFormats}>
          <Trans values={{
            formats: acceptedFileProperties.map(property => `(${property.types.map(getFileExtensionName)
              .join(', ')}) - max size: ${getSizeInMb(property.maxSize || MaximumFileSize.DEFAULT)}`).join(', ')
          }}>
            {`${TransPrefix}.SUPPORTED_FORMATS`}
          </Trans>
        </div>
      }
    </div>
  );

  const spanTag = <span className={clsx(styles.selectFile, {[styles.selectFile_disabled]: disabled})} />;
  const fileUploadClassName = clsx(
    styles.fileUpload,
    {
      [styles.fileUpload_error]: !isNil(error) || !isNil(externalError),
      [styles.fileUpload_disabled]: disabled
    });

  return (
    <div className={clsx(styles.wrapper, className)}>
      {label && LabelWithAcceptedFilesInfo}
      <div className={styles.fileUploadWrapper}>
        <div className={fileUploadClassName}>
          <div>
            <Trans components={{spanTag}}>
              {`${TransPrefix}.DROP_FILE_HERE_OR_SELECT`}
            </Trans>
          </div>
          <input accept={getFlattenFileTypes()?.toString() ?? ''}
                 className={clsx(styles.input, {[styles.input_disabled]: disabled})}
                 onBlur={onBlur}
                 onChange={onInputChange}
                 onClick={handleResetFileValue}
                 onDropCapture={handleResetFileValue}
                 disabled={disabled}
                 multiple={false}
                 type='file' />
        </div>
        {
          error && <div className={styles.error}>{error}</div>
        }
        {
          externalError && <div className={styles.error}>{externalError}</div>
        }
        {
          image && fileName && (
            <div className={styles.row}>
              {isFilePdf ? (
                <div className={styles.logoWrapper}>
                  <PdfLogo />
                </div>
              ) : isFileAudio || isFileVideo ? (
                <div className={styles.logoWrapper}>
                  <PlayLogo />
                </div>
              ) : (
                <div className={styles.imageWrapper}>
                  <img className={styles.image} src={image} />
                </div>
              )}
              {fileName}
              <IconButtonSwitch className={styles.removeFile}
                                ariaLabel={'remove file'}
                                onClick={removeFile}
                                disabled={disabled}
                                icon={<BinIcon />}
                                bordered={false} />
              <IconButtonSwitch className={styles.downloadFile}
                                ariaLabel={'download file'}
                                onClick={downloadFile}
                                icon={<DownloadIcon />}
                                bordered={false} />
              {(isFilePdf || isFileImage) && <FilePreviewDialog file={value} />}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default NxFileUpload;
